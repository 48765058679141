<template>
  <div class="game-template">
    <div>
      <van-cell-group inset>
        <van-form
          @submit="onSubmit"
          input-align="right"
          :scroll-to-error="true"
          ref="formSubmit"
        >
          <van-cell required v-if="numType == 1" title="购买数量" value="">
            <template #right-icon>
              <van-stepper
                v-model="BuyNum"
                integer
                :min="minBuyNum"
                :max="maxBuyNum"
                input-width="1rem"
                :rules="[{ required: true, message: `请输入购买数量` }]"
              />
            </template>
          </van-cell>
          <van-cell
            required
            v-if="numType == 2"
            title="购买数量"
            is-link
            :value="BuyNum"
            @click="changeBuyNum"
            :rules="[{ required: true, message: `请选择购买数量` }]"
          />
          <van-field
            class="field-input"
            v-model="item.value"
            v-for="(item, i) in Inputs"
            :key="i"
            required
            :label="item.name"
            :placeholder="`请输入${item.name}`"
            :type="item.id == 'ChargePWD' ? 'password' : 'text'"
            @blur="inputBlur"
            trigger="onBlur"
            :rules="[
              {
                required: true,
                message: `请输入${item.name}`,
              },
              {
                data: item,
                validator,
                message: `请输入正确格式的${item.name}`,
              },
            ]"
          />
          <van-field
            required
            readonly
            clickable
            name="picker"
            v-if="gameList && gameList.length > 0"
            :label="ChargeGamelabel"
            is-link
            :placeholder="`请选择${ChargeGamelabel}`"
            v-model="this.formData.ChargeGame"
            :rules="[{ required: true }]"
            @click="changeGame"
          />
          <van-field
            required
            readonly
            clickable
            name="picker"
            v-if="ChargeRegionData && ChargeRegionData.length > 0"
            :label="ChargeRegionlabel"
            is-link
            :placeholder="`请选择${ChargeRegionlabel}`"
            v-model="this.formData.ChargeRegion"
            :rules="[{ required: true }]"
            @click="changeRegion"
          />
          <van-field
            required
            readonly
            clickable
            name="picker"
            v-if="ChargeServerData && ChargeServerData.length > 0"
            :label="ChangeServelabel"
            is-link
            :placeholder="`请选择${ChangeServelabel}`"
            v-model="this.formData.ChargeServer"
            :rules="[{ required: true }]"
            @click="changeServe"
          />
          <van-field
            required
            readonly
            clickable
            name="picker"
            v-if="ChargeTypeData && ChargeTypeData.length > 0"
            label="游戏类型"
            is-link
            placeholder="请选择类型"
            v-model="this.formData.ChargeType"
            :rules="[{ required: true }]"
            @click="changeType"
          />
        </van-form>
      </van-cell-group>
      <!-- 选择游戏 -->
      <van-popup v-model="showGamePicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="gameList"
          @cancel="showGamePicker = false"
          @confirm="onConfirmGame"
        />
      </van-popup>
      <!-- 选择区 -->
      <van-popup v-model="showRegionPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="ChargeRegionData"
          @cancel="showRegionPicker = false"
          @confirm="onConfirmRegion"
        />
      </van-popup>
      <!-- 选择服 -->
      <van-popup v-model="showServerPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="ChargeServerData"
          @cancel="showServerPicker = false"
          @confirm="onConfirmServer"
        />
      </van-popup>
      <!-- 选择类型 -->
      <van-popup v-model="showTypePicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="ChargeTypeData"
          @cancel="showTypePicker = false"
          @confirm="onConfirmType"
        />
      </van-popup>
      <!-- 选择数量 -->
      <van-popup v-model="showNumPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="numList"
          @cancel="showNumPicker = false"
          @confirm="onConfirmNum"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import {
  Field,
  CellGroup,
  Cell,
  Popup,
  Picker,
  Stepper,
  Form,
  Button,
} from "vant";
export default {
  name: "GameTemplate",
  props: {
    data: {
      default: () => {},
    },
    filterTemplateData: {
      default: null,
    },
  },
  components: {
    [Field.name]: Field,
    [Form.name]: Form,
    [Cell.name]: Cell,
    [Popup.name]: Popup,
    [CellGroup.name]: CellGroup,
    [Picker.name]: Picker,
    [Stepper.name]: Stepper,
    [Button.name]: Button,
  },
  data() {
    return {
      tempData: null,
      gameList: null, // 游戏
      ChargeRegionData: null, // 游戏数据 - 区
      ChargeServerData: null, // 服
      ChargeTypeData: null, // 充值类型
      Inputs: null, // 充值填写信息
      showGamePicker: false,
      showRegionPicker: false,
      showServerPicker: false,
      showTypePicker: false,
      showNumPicker: false,
      selectedGameList: "",
      selectedGame: "",
      selectedRegion: "",
      selectedServer: "",
      selectedType: "",
      numList: [],
      BuyNum: 1,
      maxBuyNum: 1,
      minBuyNum: 1,
      numType: 1,
      formData: {},
      ChargeAccount: "",
    };
  },
  computed: {
    ChargeGamelabel: {
      get() {
        return (
          (this.filterTemplateData && this.filterTemplateData.chargeGame) ||
          "游戏"
        );
      },
      set() {},
    },
    ChargeRegionlabel: {
      get() {
        return (
          (this.filterTemplateData && this.filterTemplateData.changeRegion) ||
          "区"
        );
      },
      set() {},
    },
    ChangeServelabel: {
      get() {
        return (
          (this.filterTemplateData && this.filterTemplateData.changeServe) ||
          "服"
        );
      },
      set() {},
    },
  },
  watch: {
    BuyNum(newVal, oldVal) {
      if (newVal != oldVal) {
        this.$emit("changeBuyNum", newVal);
      }
    },
  },
  mounted() {
    console.log(this.data);
    if (this.data) {
      const {
        isServiceArea,
        elementInfo = {},
        gameTempaltePreviewList = [],
      } = this.data;
      // 解析区服
      let arr = [];
      // 有区服信息
      this.gameList = (gameTempaltePreviewList || []).map((item) => {
        return {
          ...item,
          text: item.ChargeGame,
        };
      });
      this.Inputs = (elementInfo.inputs || [])
        .filter((item) => item.id != "ChargeAccount")
        .map((v) => {
          if (v.id == "ContactType") {
            //手机
            return {
              ...v,
              validTxt: /^1(3|4|5|6|7|8|9)\d{9}$/,
            };
          } else if (v.id == "ContactQQ") {
            // qq
            return {
              ...v,
              validTxt: /^[1-9][0-9]{4,9}$/,
            };
          } else if (v.id == "ChargePWD") {
            // 密码
            return {
              ...v,
              validTxt: /^[a-zA-Z0-9_-]{4,16}$/, //4到16位（字母，数字，下划线，减号）
            };
          } else if (v.id == "ChargeAccount") {
            return v;
            // 账号
            // v.validTxt = /^[a-zA-Z0-9_-]{4,16}$/;//4到16位（字母，数字，下划线，减号）
          } else if (v.id == "ChargeWeiXin") {
            // 微信
            return {
              ...v,
              validTxt: /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/,
            };
          }
          return v;
        });

      // 设置购买数量
      const { value } = elementInfo.chargeNum || {};
      this.renderNumList(value);
    }
  },
  methods: {
    inputBlur() {
      document.body.scrollIntoView(); // 回顶部
    },
    // 生成购买数量
    generateArray(start, end) {
      return Array.from(new Array(Number(end) + 1).keys()).slice(Number(start));
    },
    // 设置数量列表
    renderNumList(Value) {
      if (!Value) {
        this.minBuyNum = 1;
        this.maxBuyNum = 1;
        this.numType = 1;
        return;
      }
      if (!!Number(Value)) {
        this.minBuyNum = Number(Value);
        this.maxBuyNum = Number(Value);
        this.numType = 1;
        return;
      }
      // Value有三种模式：
      // 1:区间【1-1000】
      // 2:分段【1|2|5|10|50|100|500】
      // 3:综合，前两种模板的组合模式【1-10|20|30|50】
      const type1 = Value.indexOf("-") != -1 && Value.indexOf("|") == -1;
      const type2 = Value.indexOf("-") == -1 && Value.indexOf("|") != -1;
      const type3 = Value.indexOf("-") != -1 && Value.indexOf("|") != -1;
      if (type1) {
        const numArr = Value.split("-") || [];
        this.minBuyNum = Number(numArr[0] || 1);
        this.maxBuyNum = Number(numArr[1] || 1);
        this.numType = 1;
        this.BuyNum = Number(numArr[0] || 1) || "";
        return;
      }
      if (type2) {
        this.numList = (Value.split("|") || []).map((item) => {
          return {
            text: item * 1,
          };
        });
        this.numType = 2;
        this.$nextTick(() => {
          this.BuyNum = (this.numList[0] && this.numList[0].text) || "";
        });
        return;
      }
      if (type3) {
        const type3Arr = Value.split("|");
        const one = type3Arr.shift().split("-");
        this.numList = this.generateArray(one[0] || 1, one[1] || 1)
          .concat(type3Arr)
          .map((item) => {
            return {
              text: item * 1,
            };
          });
        this.numType = 2;
        this.$nextTick(() => {
          this.BuyNum = (this.numList[0] && this.numList[0].text) || "";
        });
        return;
      }
      this.minBuyNum = 1;
      this.maxBuyNum = 300000;
      this.numType = 1;
    },

    // 修改游戏
    changeGame() {
      this.showGamePicker = true;
    },

    // 确认选择游戏
    onConfirmGame(value) {
      console.log(value);
      const { ChargeRegion, ChargeServer, ChargeType } = value.gameList || {};
      this.selectedGameList = value.gameList || {};
      console.log(value);
      this.formData.ChargeGame = value.ChargeGame || "";
      this.ChargeRegionData = (ChargeRegion || []).map((item) => {
        return {
          ...item,
          text: item.name,
        };
      }); // 区
      this.ChargeServerData = (ChargeServer || []).map((item) => {
        return {
          ...item,
          text: item.name,
        };
      }); // 服
      this.ChargeTypeData = (ChargeType || []).map((item) => {
        return {
          ...item,
          text: item.name,
        };
      }); // 类型
      this.showGamePicker = false;
      this.formData.ChargeRegion = "";
      this.formData.ChargeServer = "";
      this.formData.ChargeType = "";
    },

    // 修改区
    changeRegion() {
      this.showRegionPicker = true;
    },
    // 修改服
    changeServe() {
      this.showServerPicker = true;
    },
    // 修改类型
    changeType() {
      this.showTypePicker = true;
    },
    // 确认区
    onConfirmRegion(value) {
      const { ChargeServer, ChargeType } = value || {};
      console.log(value);
      this.formData.ChargeRegion = value.text || "";
      if (ChargeServer && ChargeServer.length > 0) {
        this.ChargeServerData = ChargeServer.map((item) => {
          return {
            ...item,
            text: item.name,
          };
        });
      } else {
        const { ChargeServer = [] } = this.selectedGameList;
        this.ChargeServerData = ChargeServer.map((item) => {
          return {
            ...item,
            text: item.name,
          };
        });
      }
      if (ChargeType && ChargeType.length > 0) {
        this.ChargeTypeData = ChargeType.map((item) => {
          return {
            ...item,
            text: item.name,
          };
        });
      } else {
        const { ChargeType = [] } = this.selectedGameList;
        this.ChargeTypeData = ChargeType.map((item) => {
          return {
            ...item,
            text: item.name,
          };
        });
      }
      this.showRegionPicker = false;
      this.formData.ChargeServer = "";
      this.formData.ChargeType = "";
    },
    // 确认服
    onConfirmServer(value) {
      console.log(value);
      this.formData.ChargeServer = value.text || "";
      this.showServerPicker = false;
    },
    // 确认类型
    onConfirmType(value) {
      console.log(value);
      this.formData.ChargeType = value.text || "";
      this.showTypePicker = false;
    },
    // 修改数量
    changeBuyNum() {
      this.showNumPicker = true;
    },
    // 确认数量
    onConfirmNum(value) {
      const { text = 1 } = value || {};
      this.BuyNum = text;
      this.showNumPicker = false;
    },

    validator(val, item) {
      if (!item || !item.data || !item.data.validTxt) return true;
      return item.data.validTxt.test(val);
    },

    toBuy(callFnc) {
      this.callFnc = callFnc;
      this.$refs.formSubmit.submit();
    },

    onSubmit() {
      const inputObj = {};
      if (this.Inputs) {
        this.Inputs.forEach((item) => {
          inputObj[item.id] = item.value;
        });
      }
      const data = {
        ...inputObj,
        ...this.formData,
        ChargeAccount: this.ChargeAccount,
        buyNum: this.BuyNum,
      };
      for (let key in data) {
        if (data[key] === "") {
          delete data[key];
        }
      }
      console.log(data);
      this.callFnc && this.callFnc(data);
    },
  },
};
</script>

<style lang="less" scoped>
.game-template {
  .van-field__label {
    color: #565656;
  }
  .van-cell {
    color: #565656;
  }
  .van-cell::after {
    border: 0;
  }
  .van-hairline--top-bottom::after {
    border: 0;
  }

  /deep/ .van-cell__title {
    color: #000 !important;
  }

  /deep/ .van-field__error-message {
    text-align: right;
  }

  /deep/ .van-cell-group--inset {
    margin: 0 !important;
  }

  .field-input {
    /deep/ .van-field__control::placeholder {
      color: #ccc !important;
    }
  }

  .exchange-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 1rem;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    z-index: 100;
  }
  .pay-btn {
    width: 6.7rem;
    height: 0.8rem;
    margin: 0 auto;
    background: linear-gradient(90deg, #ff9577 0%, #ff3a20 100%);
    border-radius: 0.4rem;
    font-size: 0.36rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.01rem;
  }
}
</style>
